import { SelectOption } from "@/util/select";

export function defaultSelectSearchFilter(options: SelectOption[], search: string): SelectOption[] {
    search = search.trim();
    if (!search) {
        return options;
    }

    return options.filter(option => {
        return option.label.toLowerCase().includes(search.toLowerCase());
    });
}
