import { IonContent } from "@ionic/react";
import { ReactNode } from "react";
import "./settingsPageContent.scss";

type SettingsPageContentProps = {
    children: ReactNode;
};

export function SettingsPageContent(props: SettingsPageContentProps) {
    return (
        <IonContent className="settings-page-content">
            <div className="settings-page-content__container">{props.children}</div>
        </IonContent>
    );
}
