import { Employee } from "@farmact/model/src/model/Employee";
import { OperatingUnit } from "@farmact/model/src/model/OperatingUnit";
import { Role } from "@farmact/model/src/model/Role";
import { ModelOrId, getModelId } from "./select";
import { intersect } from "./set";

/**
 * Check if the given employee should be able to access data
 * that is assigned to one or many operating units
 */
export function getEmployeeHasAccess(
    loggedInEmployee: Employee | undefined,
    operatingUnits: Array<ModelOrId<OperatingUnit>> | ModelOrId<OperatingUnit>
): boolean {
    if (!loggedInEmployee) {
        return false;
    }
    if (loggedInEmployee.role === Role.OWNER) {
        return true;
    }

    operatingUnits = Array.isArray(operatingUnits) ? operatingUnits : [operatingUnits];
    const operatingUnitIds = operatingUnits.map(getModelId);

    return intersect(new Set(loggedInEmployee.operatingUnitIds), new Set(operatingUnitIds)).size > 0;
}

export function getEmployeeIsImplicitOwner(
    loggedInEmployee: Employee | undefined,
    allOperatingUnits: ModelOrId<OperatingUnit>[]
): boolean {
    if (!loggedInEmployee) {
        return false;
    }
    if (loggedInEmployee.role === Role.OWNER) {
        return true;
    }

    // only ADMIN employees (besides OWNER) are considered implicit owners
    if (loggedInEmployee.role !== Role.ADMIN) {
        return false;
    }

    // employees that are member of ALL operating units are considered to be owners
    const employeeIsInAllOperatingUnits = allOperatingUnits.every(operatingUnit => {
        return loggedInEmployee.operatingUnitIds.includes(getModelId(operatingUnit));
    });
    return employeeIsInAllOperatingUnits;
}

/**
 * Get all operating unit ids that the given employee can
 * freely change (add or remove from an entity e.g. customers or machines)
 */
export function getEmployeeChangeableOperatingUnitIds(
    loggedInEmployee: Employee | undefined,
    allOperatingUnits: ModelOrId<OperatingUnit>[]
): string[] {
    if (!loggedInEmployee) {
        return [];
    }

    const allOperatingUnitIds = allOperatingUnits.map(getModelId);
    if (loggedInEmployee.role === Role.OWNER) {
        return allOperatingUnitIds;
    }

    return allOperatingUnitIds.filter(operatingUnitId => {
        return loggedInEmployee.operatingUnitIds.includes(operatingUnitId);
    });
}
