import { useEffect, useState } from "react";

export function useLoading(loading: boolean, timeoutLength = 300) {
    const [showSpinner, setShowSpinner] = useState(false);
    if (!loading && showSpinner) {
        setShowSpinner(false);
    }

    useEffect(() => {
        if (!loading) {
            return;
        }

        const timeout = setTimeout(() => {
            setShowSpinner(true);
        }, timeoutLength);

        return () => {
            clearTimeout(timeout);
        };
    }, [loading, timeoutLength]);

    return showSpinner;
}
