import { useMemo } from "react";
import { useUserContext } from "@/components/authentication/Session/useUserContext";
import { getEmployeeHasAccess } from "@/util/accessUtils";

export function useFilterEmployeeHasAccess<T extends WithOperatingUnitIds>(data: T[]): T[];
export function useFilterEmployeeHasAccess<T extends Identifiable>(
    data: T[],
    accessor: OperatingUnitIdsAccessor<T>
): T[];

export function useFilterEmployeeHasAccess<T extends Identifiable>(
    data: T[],
    accessor?: OperatingUnitIdsAccessor<T>
): T[] {
    const { employee: loggedInEmployee } = useUserContext();

    return useMemo(() => {
        return data.filter(item => {
            let operatingUnitIds: string[] = [];
            if (accessor) {
                const res = accessor(item);
                if (res === true) {
                    return true;
                }

                operatingUnitIds = Array.isArray(res) ? res : [res];
            } else if ("operatingUnitIds" in item && Array.isArray(item.operatingUnitIds)) {
                operatingUnitIds = item.operatingUnitIds;
            }

            return getEmployeeHasAccess(loggedInEmployee, operatingUnitIds);
        });
    }, [accessor, data, loggedInEmployee]);
}

type Identifiable = {
    id: string;
};
type WithOperatingUnitIds = Identifiable & {
    operatingUnitIds: string[];
};

/**
 * Accessor to get the (one or many) operating unit ids from an item.
 * Return `true` to always include the item in the final list.
 */
type OperatingUnitIdsAccessor<T> = (item: T) => string | string[] | true;
