import { useMemo } from "react";
import { MultiSelectProps as MultiSelectElementProps } from "@/components/inputs/MultiSelect/MultiSelect";
import { SingleSelectProps as SingleSelectElementProps } from "@/components/inputs/SingleSelect/SingleSelect";

type SelectOptionModel = {
    id: string;
};

export type SelectOption = {
    value: string;
    label: string;
    fixed?: boolean;
    disabled?: boolean;
    incompatible?: boolean;
    group?: string;
};

export type SelectOptionCreator<T extends SelectOptionModel> = (model: T) => SelectOption;
export type SelectOptionSorter<T extends SelectOptionModel> = (a: T, b: T) => number;

export type ModelOrId<T extends SelectOptionModel> = T | T["id"];

export function getModelId<T extends SelectOptionModel>(modelOrId: ModelOrId<T>): T["id"] {
    if (typeof modelOrId === "string") {
        return modelOrId;
    }

    return modelOrId.id;
}

type CommonSelectProps<T extends SelectOptionModel> = {
    data?: T[];
    //
    optionCreator?: SelectOptionCreator<T>;
    sorter?: SelectOptionSorter<T>;
};

/**
 * --------------------------------------------------
 * Single selection
 * --------------------------------------------------
 */

export type SingleSelectProps<T extends SelectOptionModel> = Omit<
    SingleSelectElementProps,
    "data" | "value" | "onChange"
> &
    CommonSelectProps<T> & {
        //
        value: ModelOrId<T> | null | undefined;
        onValueChange?: (modelId: T["id"] | null) => void;
        onModelChange?: (model: T | null) => void;
    };

type UseSelectOptionsParams<T extends SelectOptionModel> = {
    optionCreator: SelectOptionCreator<T>;
    sorter?: SelectOptionSorter<T>;
};

/**
 * Create Select options for every given model using the specified creator
 * @param models
 * @param params
 * @returns
 */
export function useSelectOptions<T extends SelectOptionModel>(
    models: T[],
    params: UseSelectOptionsParams<T>
): SelectOption[] {
    const { optionCreator, sorter } = params;

    return useMemo(() => {
        const sorted = sorter ? [...models].sort(sorter) : models;

        return sorted.map(optionCreator);
    }, [models, optionCreator, sorter]);
}

type SingleSelectChangeHandler<T extends SelectOptionModel> = (value: T["id"] | null) => void;
export function getSingleSelectChangeHandler<T extends SelectOptionModel>(
    models: T[],
    props: Pick<SingleSelectProps<T>, "onModelChange" | "onValueChange">
): SingleSelectChangeHandler<T> {
    return value => {
        const { onValueChange, onModelChange } = props;

        onValueChange?.(value);

        if (onModelChange) {
            const selectedModel = models.find(model => {
                return model.id === value;
            });
            onModelChange?.(selectedModel || null);
        }
    };
}

/**
 * --------------------------------------------------
 * Multi selection
 * --------------------------------------------------
 */

export type MultiSelectProps<T extends SelectOptionModel> = Omit<
    MultiSelectElementProps,
    "data" | "values" | "onChange"
> &
    CommonSelectProps<T> & {
        values: ModelOrId<T>[];
        onValuesChange?: (modelIds: Array<T["id"]>) => void;
        onModelsChange?: (models: T[]) => void;
    };

type MultiSelectChangeHandler<T extends SelectOptionModel> = (values: Array<T["id"]>) => void;
export function getMultiSelectChangeHandler<T extends SelectOptionModel>(
    models: T[],
    props: Pick<MultiSelectProps<T>, "onModelsChange" | "onValuesChange">
): MultiSelectChangeHandler<T> {
    return values => {
        const { onValuesChange, onModelsChange } = props;

        onValuesChange?.(values);

        if (onModelsChange) {
            const selectedModels = models.filter(model => {
                return values.includes(model.id);
            });
            onModelsChange?.(selectedModels);
        }
    };
}
