import { createContext } from "react";

type SettingsNavigationContext = {
    value: string | null;
    setValue: (active: string) => void;
};

export const SettingsNavigationContext = createContext<SettingsNavigationContext>({
    value: null,
    setValue: () => {},
});
