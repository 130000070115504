import { IonButton } from "@ionic/react";
import { ComponentProps, ForwardedRef, forwardRef } from "react";

export const SaveButton = forwardRef(
    (props: ComponentProps<typeof IonButton>, ref: ForwardedRef<HTMLIonButtonElement>) => {
        const { children = "Speichern", ...restProps } = props;

        return (
            <IonButton ref={ref} color="primary" fill="solid" {...restProps}>
                {children}
            </IonButton>
        );
    }
);
