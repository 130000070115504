import clsx from "clsx";
import { ComponentPropsWithoutRef, MouseEvent } from "react";
import { useSettingsNavigationContext } from "@/components/settings/SettingsPage/SettingsNavigation/useSettingsNavigationContext";
import "./settingsNavigationTab.scss";

type SettingsNavigationTabOwnProps = {
    value: string;
};
type SettingsNavigationTabProps = SettingsNavigationTabOwnProps &
    Omit<ComponentPropsWithoutRef<"button">, keyof SettingsNavigationTabOwnProps>;

export function SettingsNavigationTab(props: SettingsNavigationTabProps) {
    const { value, ...restProps } = props;

    const { value: activeValue, setValue } = useSettingsNavigationContext();

    const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
        setValue(value);
        props.onClick?.(event);
    };

    return (
        <button
            {...restProps}
            className={clsx(
                "settings-navigation-tab",
                {
                    "settings-navigation-tab--active": activeValue === value,
                },
                props.className
            )}
            onClick={handleClick}
        />
    );
}
