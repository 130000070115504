import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Combobox } from "@mantine/core";
import clsx from "clsx";
import { SelectOption } from "@/util/select";
import "./comboboxOption.scss";

type ComboboxOptionProps = {
    option: SelectOption;
    selected: boolean;
};

export function ComboboxOption(props: ComboboxOptionProps) {
    return (
        <Combobox.Option
            value={props.option.value}
            disabled={props.option.disabled}
            className={clsx("combobox-option", {
                "combobox-option--selected": props.selected,
                "combobox-option--disabled": props.option.disabled,
                "combobox-option--incompatible": props.option.incompatible,
            })}>
            {props.option.label}

            {props.selected && <FontAwesomeIcon icon={faCheck} className="combobox-option__selected-icon" />}
        </Combobox.Option>
    );
}
