export enum Role {
    OWNER = "OWNER",
    ADMIN = "ADMIN",
    PLANNER = "PLANNER",
    EMPLOYEE = "EMPLOYEE",
    TEMPORARY_WORKER = "TEMPORARY_WORKER",
    SUBCONTRACTOR = "SUBCONTRACTOR",
    CUSTOMER = "CUSTOMER",
    NOTHING = "NOTHING",
}

export function getRoleName(role?: Role): string {
    switch (role) {
        case Role.OWNER:
            return "Eigentümer";
        case Role.ADMIN:
            return "Administrator";
        case Role.PLANNER:
            return "Disponent";
        case Role.EMPLOYEE:
            return "Mitarbeiter";
        case Role.TEMPORARY_WORKER:
            return "Aushilfsfahrer";
        case Role.SUBCONTRACTOR:
            return "Subunternehmer";
        case Role.CUSTOMER:
            return "Kunde";
        default:
            return "Sonstige";
    }
}
