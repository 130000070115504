import { AppCompany } from "@farmact/model/src/model/AppCompany";
import { CompanyMeta, Variant } from "@farmact/model/src/model/CompanyMeta";
import { OperatingUnit } from "@farmact/model/src/model/OperatingUnit";
import { Context, createContext } from "react";

export type AppCompanyContextType = {
    appCompany?: AppCompany;
    companyMeta?: CompanyMeta;
    operatingUnits: OperatingUnit[];
    operatingUnitsIncludingArchived: OperatingUnit[];
    defaultOperatingUnit?: OperatingUnit;
};

const AppCompanyContext: Context<AppCompanyContextType> = createContext<AppCompanyContextType>({
    appCompany: undefined,
    companyMeta: new CompanyMeta({
        isSuspended: false,
        variant: Variant.AGRICULTURAL_CONTRACTOR,
        multipleOperatingUnitsEnabled: false,
    }),
    operatingUnits: [],
    operatingUnitsIncludingArchived: [],
    defaultOperatingUnit: undefined,
});

export default AppCompanyContext;
