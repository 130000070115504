import * as FormControl from "@/components/inputs/FormControl";
import {
    SingleOperatingUnitSelect,
    SingleOperatingUnitSelectProps,
} from "@/components/inputs/operatingUnitInputs/SingleOperatingUnitSelect";
import "./settingsPageHeaderOperatingUnitSelection.scss";

type SettingsPageHeaderOperatingUnitSelectionProps = SingleOperatingUnitSelectProps;

export const SettingsPageHeaderOperatingUnitSelection = (props: SettingsPageHeaderOperatingUnitSelectionProps) => {
    return (
        <FormControl.Root className="settings-page-header-operating-unit-selection">
            <FormControl.Label>Betrieb</FormControl.Label>
            <SingleOperatingUnitSelect {...props} />
        </FormControl.Root>
    );
};
