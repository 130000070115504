import { useHistory } from "react-router";
import * as DetailPageFooter from "@/components/DetailPageFooter";
import { DiscardButton } from "@/components/organization/sharedComponents/DiscardButton/DiscardButton";
import { SaveButton } from "@/components/organization/sharedComponents/SaveButton/SaveButton";

type SettingsPageFooterProps = {
    onSave: () => void;
};

export const SettingsPageFooter = (props: SettingsPageFooterProps) => {
    const history = useHistory();

    return (
        <DetailPageFooter.Root>
            <DetailPageFooter.Actions>
                <DiscardButton onClick={history.goBack} />
                <SaveButton onClick={props.onSave} />
            </DetailPageFooter.Actions>
        </DetailPageFooter.Root>
    );
};
