export function intersect<T>(a: Set<T>, b: Set<T>): Set<T> {
    return new Set<T>(
        Array.from(a).filter(item => {
            return b.has(item);
        })
    );
}

export function intersectMany<T>(...sets: Set<T>[]): Set<T> {
    if (sets.length < 2) {
        return new Set(...sets);
    }

    const [first, second, ...rest] = sets;

    return rest.reduce(intersect, intersect(first, second));
}
