import { IonButton } from "@ionic/react";
import { ComponentPropsWithoutRef } from "react";

type DiscardButtonProps = ComponentPropsWithoutRef<typeof IonButton>;

export const DiscardButton = (props: DiscardButtonProps) => {
    const { children = "Verwerfen", ...restProps } = props;

    return (
        <IonButton color="medium" fill="solid" {...restProps}>
            {children}
        </IonButton>
    );
};
