import { OperatingUnit } from "@farmact/model/src/model/OperatingUnit";
import { useAppCompanyContext } from "@/components/authentication/useAppCompanyContext";
import { SingleSelect } from "@/components/inputs/SingleSelect/SingleSelect";
import { useFilterEmployeeHasAccess } from "@/util/customHooks/useFilterEmployeeHasAccess";
import { SingleSelectProps, getModelId, getSingleSelectChangeHandler, useSelectOptions } from "@/util/select";
import { createOperatingUnitSelectItem, defaultSelectableOperatingUnitsSorter } from "./operatingUnitInputsUtils";

export type SingleOperatingUnitSelectProps = SingleSelectProps<OperatingUnit>;

export const SingleOperatingUnitSelect = (props: SingleOperatingUnitSelectProps) => {
    const {
        placeholder = "Betrieb auswählen",
        data: controlledOperatingUnits,
        optionCreator = createOperatingUnitSelectItem,
        sorter = defaultSelectableOperatingUnitsSorter,
        value,
        onValueChange,
        onModelChange,
        ...restProps
    } = props;

    const { operatingUnits: uncontrolledOperatingUnits } = useAppCompanyContext();

    const operatingUnits = controlledOperatingUnits || uncontrolledOperatingUnits;

    const selectableOperatingUnits = useFilterEmployeeHasAccess(operatingUnits, operatingUnit => {
        if (value && getModelId(value) === operatingUnit.id) {
            return true;
        }
        return operatingUnit.id;
    });

    const items = useSelectOptions(selectableOperatingUnits, {
        optionCreator,
        sorter,
    });

    return (
        <SingleSelect
            {...restProps}
            placeholder={placeholder}
            searchPlaceholder="Betriebe durchsuchen"
            data={items}
            value={value ? getModelId(value) : null}
            onChange={getSingleSelectChangeHandler(selectableOperatingUnits, {
                onValueChange,
                onModelChange,
            })}
        />
    );
};
