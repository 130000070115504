import clsx from "clsx";
import { ComponentPropsWithoutRef } from "react";
import { SettingsNavigationContext } from "@/components/settings/SettingsPage/SettingsNavigation/SettingsNavigationContext";
import "./settingsNavigationRoot.scss";

type SettingsNavigationRootOwnProps = {
    value: string | null;
    onValueChange: (active: string) => void;
};
type SettingsNavigationRootProps = SettingsNavigationRootOwnProps &
    Omit<ComponentPropsWithoutRef<"div">, keyof SettingsNavigationRootOwnProps>;

export const SettingsNavigationRoot = (props: SettingsNavigationRootProps) => {
    const { value, onValueChange, ...restProps } = props;

    return (
        <SettingsNavigationContext.Provider value={{ value, setValue: onValueChange }}>
            <div {...restProps} className={clsx("settings-navigation-root", props.className)} />
        </SettingsNavigationContext.Provider>
    );
};
