import { OperatingUnit } from "@farmact/model/src/model/OperatingUnit";
import { getOperatingUnitName } from "@/util/operatingUnitsUtils";
import { SelectOption, SelectOptionCreator, SelectOptionSorter } from "@/util/select";

export function createOperatingUnitSelectItem(operatingUnit: OperatingUnit): SelectOption {
    return {
        value: operatingUnit.id,
        label: getOperatingUnitSelectLabel(operatingUnit),
        disabled: operatingUnit.archived,
    };
}

function getOperatingUnitSelectLabel(operatingUnit: OperatingUnit): string {
    const name = getOperatingUnitName(operatingUnit);
    if (operatingUnit.archived) {
        return `${name} (archiviert)`;
    }

    return name;
}

export const defaultSelectableOperatingUnitsSorter: SelectOptionSorter<OperatingUnit> = (a, b) => {
    if (a.archived && b.archived) {
        return 0;
    }
    if (a.archived) {
        return 1;
    }
    if (b.archived) {
        return -1;
    }

    return getOperatingUnitName(a).localeCompare(getOperatingUnitName(b));
};

type CreateOperatingUnitSelectItemCreatorComboboxItems = {
    /**
     * If `true`, allows selecting archived operating units
     * @default false
     */
    allowArchived?: boolean;
};
export function createOperatingUnitSelectItemCreator(
    options: CreateOperatingUnitSelectItemCreatorComboboxItems = {}
): SelectOptionCreator<OperatingUnit> {
    const { allowArchived = false } = options;

    return operatingUnit => {
        return {
            value: operatingUnit.id,
            label: getOperatingUnitSelectLabel(operatingUnit),
            disabled: allowArchived ? false : operatingUnit.archived,
        };
    };
}
