import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { ComponentPropsWithoutRef } from "react";
import "./settingsPageHeaderCaption.scss";

type SettingsPageHeaderCaptionProps = ComponentPropsWithoutRef<"p">;

export function SettingsPageHeaderCaption(props: SettingsPageHeaderCaptionProps) {
    return (
        <p {...props} className={clsx("settings-page-header-caption", props.className)}>
            <FontAwesomeIcon icon={faQuestionCircle} className="settings-page-header-caption__icon" />
            {props.children}
        </p>
    );
}
